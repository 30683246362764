import React, { useEffect, useState, useContext } from "react";

import { azureTokenContext, userInfoContext } from "../../App";

import * as ApiCalls from "./ApiCalls";

import "../../style/unresolvedValidationsBox.css";

import moment from "moment";
import "moment/locale/nb";

export default function UnresolvedValidationsBox() {
  const [unresolvedValidations, setUnresolvedValidations] = useState([]);
  const [userInfo, setUserInfo] = useContext(userInfoContext);
  const azureToken = useContext(azureTokenContext);

  useEffect(() => {
    if (userInfo.id) {
      checkUnresolvedValidation();
    }
  }, [userInfo.id]);

  const checkUnresolvedValidation = async () => {
    await ApiCalls.getUnresolvedValidations(userInfo, azureToken)
      .then((res) => {
        setUnresolvedValidations(res);
      })
      .catch((e) => {
        console.log("error on GetUnresolvedValidation", e);
        log("ERROR", "Failed GetUnresolvedValidation " + e.message, "Author portal", "", "");
      });
  };

  const log = (level, message, method, workItemId, userRole) => {
    ApiCalls.log(level, message, method, workItemId, userRole, userInfo.id, azureToken);
  };

  return (
    <>
      {unresolvedValidations.length !== 0 && (
        <div class="validation-box">
          <strong>
            Dine endringer er registrert {moment(unresolvedValidations[0].createdUtc).format("DD.MM.YYYY")} og vil bli oppdatert i
            løpet av en uke.
          </strong>
        </div>
      )}
    </>
  );
}
