import React, { useContext } from "react";
import { Outlet, Link, useResolvedPath } from "react-router-dom";

// import logo from "../assets/img/KarnovLogo.svg";

// Material UI Icons
import AssignmentIcon from "@mui/icons-material/Assignment";
import BookIcon from "@mui/icons-material/Book";
import HomeIcon from "@mui/icons-material/Home";
import HelpIcon from "@mui/icons-material/Help";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import EqualizerIcon from "@mui/icons-material/Equalizer";

// import { azureTokenContext } from "../App";
import { userRolesContext } from "../App";

import "../style/sideBar.css";

export default function SideBar() {
  // const azureToken = useContext(azureTokenContext);
  const userRoles = useContext(userRolesContext);

  const CustomLink = ({ children, to, ...props }) => {
    let resolved = useResolvedPath(to);
    let match;
    if (resolved.pathname.length === 1) {
      //check for home
      match = window.location.pathname === resolved.pathname;
    } else {
      //check for other urls
      match = window.location.pathname.startsWith(resolved.pathname);
    }

    // let match = useMatch({ path: resolved.pathname, end: true });

    return (
      <div className="sideBarLinksDiv">
        <Link className="navItem" id={match ? "activeNav" : ""} to={to} {...props}>
          {children}
        </Link>
      </div>
    );
  };

  return (
    <div className="sideBar">
      <a href="https://www.karnovgroup.no">
        <div className="logoContainer">
          <img src="/KarnovLogo.svg" className="App-logo" alt="logo" />
        </div>
      </a>

      <div className="sideBarNav">
        <nav>
          <CustomLink to="/">
            <HomeIcon className="navIcons" /> Hjem
          </CustomLink>
          {/* {userRoles.includes("KAuthor") && (
            <CustomLink to="/tasks">
              <AssignmentIcon className="navIcons" /> Oppgaver
            </CustomLink>
          )} */}
          {userRoles.includes("KAuthor") && (
            <CustomLink to="/authors-tasks">
              <AssignmentIcon className="navIcons" />
              Oppgaver som forfatter
            </CustomLink>
          )}
          {userRoles.includes("KContentReviewer") && (
            <CustomLink to="/contentreviewers-tasks">
              <BookIcon className="navIcons" />
              Oppgaver som fagredaktør
            </CustomLink>
          )}
          {userRoles.includes("KProofReader") && (
            <CustomLink to="/proofreaders-tasks">
              <FormatListNumberedIcon className="navIcons" />
              Oppgaver som korrekturleser
            </CustomLink>
          )}
          {
            <CustomLink to="/tasks">
              <AssignmentIcon className="navIcons" /> Alle oppgaver
            </CustomLink>
          }
          {userRoles.includes("KContentReviewer") && (
            <CustomLink to="/statistics">
              <EqualizerIcon className="navIcons" /> Statistikk
            </CustomLink>
          )}
          <CustomLink to="/profile">
            <PermIdentityIcon className="navIcons" /> Profil
          </CustomLink>
          <CustomLink to="/support">
            <HelpIcon className="navIcons" /> Support
          </CustomLink>
        </nav>
        <Outlet />
      </div>
    </div>
  );
}
