import React, { useState, useContext, useEffect } from "react";
import { azureTokenContext, userInfoContext, messageBoxContext } from "../App";
import Loading from "./Serving/Loading";
import UnresolvedValidationsBox from "./Serving/UnresolvedValidationsBox";
import { useNavigate } from "react-router-dom";

import * as ApiCalls from "./Serving/ApiCalls";

import moment from "moment";
import "moment/locale/nb";

import { Container, Typography, TextField, Button, Grid, CircularProgress, Avatar, Box } from "@mui/material";

export default function EditProfile() {
  const azureToken = useContext(azureTokenContext);
  const [userInfo, setUserInfo] = useContext(userInfoContext);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fileName, setFileName] = useState("");
  const [newProfilePicture, setNewProfilePicture] = useState(null);
  const [newImageLocation, setNewImageLocation] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  let setMessageBoxProp = useContext(messageBoxContext);

  const [newUserInfo, setNewUserInfo] = useState({});

  useEffect(() => {
    const ckeckData = async () => {
      fillNewUserDataInfo(userInfo);

      if (userInfo.id) {
        if (!hasSensitiveInfo()) {
          await addSensitiveInfoToUserInfo();
        }
        setLoading(false);
      }
    };

    ckeckData();
  }, [userInfo.id]);

  const hasSensitiveInfo = () => {
    return userInfo.socialSecurityNumber || userInfo.bankAccountNumber;
  };

  const addSensitiveInfoToUserInfo = async () => {
    await ApiCalls.GetUserSensitiveInfo(userInfo, azureToken)
      .then((res) => {
        setUserInfo(() => ({ ...res }));
        fillNewUserDataInfo(res);
      })
      .catch((e) => {
        console.log("error on GetUserSensitiveInfo", e);
        log("ERROR", "Failed GetUserSensitiveInfo " + e.message, "Author portal", "", "");
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setIsChanged(true);

    setNewUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileInputChange = (event) => {
    setIsChanged(true);
    const file = event.target.files[0];
    setNewProfilePicture(file);
    setFileName(file?.name || "");
    setNewImageLocation(URL.createObjectURL(file));
  };

  const handleSubmit = async (e) => {
    let formedData = {
      actorUserId: userInfo.id,
      id: userInfo.id,
      name: newUserInfo.name ?? userInfo.name,
      email: newUserInfo.email ?? userInfo.email,
      phone: newUserInfo.phone ?? userInfo.phone,
      userSensitiveInfo: {
        socialSecurityNumber: clearNumbers(newUserInfo.socialSecurityNumber) ?? userInfo.socialSecurityNumber,
        bankAccountNumber: clearNumbers(newUserInfo.bankAccountNumber) ?? userInfo.bankAccountNumber,
      },
      address: newUserInfo.address ?? userInfo.address,
      zipCode: newUserInfo.zipCode ?? userInfo.zipCode,
      city: newUserInfo.city ?? userInfo.city,
      country: newUserInfo.country ?? userInfo.country,
      employer: newUserInfo.employer ?? userInfo.employer,
      title: newUserInfo.title ?? userInfo.title,
      description: newUserInfo.description ?? userInfo.description,
      imageUrl: newUserInfo.imageUrl ?? userInfo.imageUrl,
    };

    if (newProfilePicture) {
      //upload new file and then change newUserInfo.imageUrl
      await ApiCalls.uploadUserProfile(userInfo, newProfilePicture, azureToken)
        .then(async (res) => {
          formedData.imageUrl = res.url;
          log("INFO", "Uploaded profile picture", "Author portal", "", "");
          await updateUserInfo(formedData);
        })
        .catch((err) => {
          console.log("error on uploading profile picture", err);
          log("ERROR", "Failed uploading profile picture " + err.message, "Author portal", "", "");
          setMessageBoxPropFunc("FailedEditUserInfo");
        });
    } else {
      await updateUserInfo(formedData);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (validateFields()) {
      setSaving(true);
      await handleSubmit().then(() => {
        resetData();
      });
    }
  };

  const resetData = () => {
    setSaving(false);
    setIsChanged(false);
    setErrors({});
    fillNewUserDataInfo(userInfo);
    setNewProfilePicture(null);
    setNewImageLocation(null);
    setFileName("");
  };

  const updateUserInfo = async (formedData) => {
    await ApiCalls.updateUserInfo(formedData, azureToken)
      .then((res) => {
        setUserInfo(() => ({ ...res.updatedUser }));
        addSensitiveInfoToUserInfo();
        log("INFO", "Edited user info", "Author portal", "", "");
        if (res.wentToManualValidation === true) {
          setMessageBoxPropFunc("WentToManualValidationEditUserInfo");
        } else {
          setMessageBoxPropFunc("SuccessEditUserInfo");
        }
        navigate("/profile");
      })
      .catch((e) => {
        log("ERROR", "Fail update user info " + e.message, "Author portal", "", "");
        console.log("error on editing user data", e);
        setMessageBoxPropFunc("FailedEditUserInfo");
      });
  };

  const setMessageBoxPropFunc = (msg) => {
    // since it is invocated by changes in prop, I want to make sure that it changes
    setMessageBoxProp("");
    setTimeout(() => {
      setMessageBoxProp(msg);
    }, 10);
  };

  const fillNewUserDataInfo = (req) => {
    setNewUserInfo({
      id: req.id,
      name: req.name,
      email: req.email,
      phone: req.phone,
      socialSecurityNumber: req.socialSecurityNumber,
      bankAccountNumber: req.bankAccountNumber,
      address: req.address,
      zipCode: req.zipCode,
      city: req.city,
      country: req.country,
      employer: req.employer,
      title: req.title,
      description: req.description,
      imageUrl: req.imageUrl,
    });
  };

  const validateFields = () => {
    let tempErrors = {};

    if (!newUserInfo.name) tempErrors.name = "Navn er påkrevd";
    if (!newUserInfo.email) {
      tempErrors.email = "E-post er påkrevd";
    } else if (!/\S+@\S+\.\S+/.test(newUserInfo.email)) {
      tempErrors.email = "Ugyldig e-postadresse";
    }
    if (newUserInfo.phone && !/^\+?\d{8,15}$/.test(newUserInfo.phone)) {
      tempErrors.phone = "Ugyldig mobilnummer";
    }
    if (newUserInfo.socialSecurityNumber && !/^\d{11}$/.test(clearNumbers(newUserInfo.socialSecurityNumber))) {
      tempErrors.socialSecurityNumber = "Fødselsnummer må ha 11 sifre";
    }

    if (newUserInfo.bankAccountNumber && !/^\d{11}$/.test(clearNumbers(newUserInfo.bankAccountNumber))) {
      tempErrors.bankAccountNumber = "Kontonummer må ha 11 sifre";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const clearNumbers = (bankAccountNumber) => {
    return bankAccountNumber ? bankAccountNumber.replace(/[.\s]/g, "") : "";
  };

  const cancelClicked = () => {
    resetData();
    navigate("/profile");
  };

  const log = (level, message, method, workItemId, userRole) => {
    ApiCalls.log(level, message, method, workItemId, userRole, userInfo.id, azureToken);
  };

  // const logMetric = (category, subCategory, status, tag, message, workItemId, userRole) => {
  //   ApiCalls.logMetric(category, subCategory, status, tag, message, workItemId, userRole, userInfo.id, azureToken);
  // };

  const styleMUITextFields = {
    backgroundColor: "#f5f5f5",
    borderRadius: "8px",

    // padding: "5px 0px",
  };

  const styleMUIGrid = {
    padding: "6px",
    backgroundColor: "white",
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Container maxWidth="lg">
            <UnresolvedValidationsBox />
            <form onSubmit={handleSave}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item xs={12} md={9} sx={styleMUIGrid}>
                      <TextField
                        fullWidth
                        label="Navn"
                        name="name"
                        value={newUserInfo.name}
                        onChange={handleChange}
                        variant="outlined"
                        sx={styleMUITextFields}
                        error={!!errors.name}
                        helperText={errors.name}
                      />
                    </Grid>
                    <Grid item xs={12} md={9} sx={styleMUIGrid}>
                      <TextField
                        fullWidth
                        label="E-post"
                        name="email"
                        value={newUserInfo.email}
                        onChange={handleChange}
                        variant="outlined"
                        sx={styleMUITextFields}
                        error={!!errors.email}
                        helperText={errors.email}
                      />
                    </Grid>
                    <Grid item xs={12} md={9} sx={styleMUIGrid}>
                      <TextField
                        fullWidth
                        label="Mobilnummer"
                        name="phone"
                        value={newUserInfo.phone}
                        onChange={handleChange}
                        variant="outlined"
                        sx={styleMUITextFields}
                        error={!!errors.phone}
                        helperText={errors.phone}
                      />
                    </Grid>
                    <Grid item xs={12} md={9} sx={styleMUIGrid}>
                      Rediger feltene under for å oppdatere din forfatterprofil som vises på karnovgroup.no/forfattere
                    </Grid>
                    <Grid item xs={12} md={9} sx={styleMUIGrid}>
                      <TextField
                        fullWidth
                        label="Tittel"
                        name="title"
                        value={newUserInfo.title}
                        onChange={handleChange}
                        variant="outlined"
                        sx={styleMUITextFields}
                      />
                    </Grid>
                    <Grid item xs={12} md={9} sx={styleMUIGrid}>
                      <TextField
                        fullWidth
                        label="Arbeidssted"
                        name="employer"
                        value={newUserInfo.employer}
                        onChange={handleChange}
                        variant="outlined"
                        sx={styleMUITextFields}
                      />
                    </Grid>
                    <Grid item xs={12} md={9} sx={styleMUIGrid}>
                      <TextField
                        fullWidth
                        label="Forfatterpresentasjon"
                        name="description"
                        value={newUserInfo.description}
                        onChange={handleChange}
                        variant="outlined"
                        sx={styleMUITextFields}
                        multiline
                        rows={5}
                      />
                    </Grid>

                    <Grid item xs={12} md={9}>
                      <input
                        type="file"
                        name="profilePicture"
                        accept="image/*"
                        onChange={handleFileInputChange}
                        style={{ display: "none" }}
                        id="profilePictureInput"
                      />
                      <br />
                      Last opp bilde til din forfatterpresentasjon. Det kan ta opptil én uke før bildet blir lagt ut på
                      forfatterprofilen.
                      <br />
                      <br />
                      <label htmlFor="profilePictureInput">
                        <Button variant="contained" color="primary" component="span">
                          Last opp
                        </Button>
                      </label>
                      <br /> <br />
                      {fileName && <Typography variant="body2">{fileName}</Typography>}
                      {newImageLocation && (
                        <Box my={2}>
                          <Avatar
                            variant="rounded"
                            src={newImageLocation ? newImageLocation : "no-profile-image.png"}
                            alt="Profile"
                            sx={{ width: 150, height: 150 }}
                          />
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item xs={12} sx={styleMUIGrid}>
                      <p style={{ marginTop: "3rem" }}>
                        Dersom du i henhold til kontrakten skal ha utbetalt honorar som privatperson, må du sørge for at disse
                        feltene er fylt ut korrekt.
                      </p>
                    </Grid>
                    <Grid item xs={12} md={9} sx={styleMUIGrid}>
                      <TextField
                        fullWidth
                        label="Postadresse (privat)"
                        name="address"
                        value={newUserInfo.address}
                        onChange={handleChange}
                        variant="outlined"
                        sx={styleMUITextFields}
                      />
                    </Grid>
                    <Grid item xs={12} md={9} sx={styleMUIGrid}>
                      <TextField
                        fullWidth
                        label="Postnummer"
                        name="zipCode"
                        value={newUserInfo.zipCode}
                        onChange={handleChange}
                        variant="outlined"
                        sx={styleMUITextFields}
                      />
                    </Grid>
                    <Grid item xs={12} md={9} sx={styleMUIGrid}>
                      <TextField
                        fullWidth
                        label="Sted"
                        name="city"
                        value={newUserInfo.city}
                        onChange={handleChange}
                        variant="outlined"
                        sx={styleMUITextFields}
                      />
                    </Grid>
                    <Grid item xs={12} md={9} sx={styleMUIGrid}>
                      <TextField
                        fullWidth
                        label="Land"
                        name="country"
                        value={newUserInfo.country}
                        onChange={handleChange}
                        variant="outlined"
                        sx={styleMUITextFields}
                      />
                    </Grid>
                    <Grid item xs={12} md={9} sx={styleMUIGrid}>
                      <TextField
                        fullWidth
                        label="Fødselsnummer"
                        name="socialSecurityNumber"
                        value={newUserInfo.socialSecurityNumber}
                        onChange={handleChange}
                        variant="outlined"
                        sx={styleMUITextFields}
                        error={!!errors.socialSecurityNumber}
                        helperText={errors.socialSecurityNumber}
                      />
                    </Grid>
                    <Grid item xs={12} md={9} sx={styleMUIGrid}>
                      <TextField
                        fullWidth
                        label="Kontonummer"
                        name="bankAccountNumber"
                        value={newUserInfo.bankAccountNumber}
                        onChange={handleChange}
                        variant="outlined"
                        sx={styleMUITextFields}
                        error={!!errors.bankAccountNumber}
                        helperText={errors.bankAccountNumber}
                      />
                    </Grid>
                    <Grid item xs={12} md={9} sx={styleMUIGrid}>
                      Dine personopplysninger blir behandlet i henhold til vår personvernerklæring og GDPR.
                    </Grid>
                  </Grid>
                </Grid>
                {/* Empty grid to make it center aligned */}
                <Grid item xs={4}></Grid>
                <Grid item xs={12} sx={{ paddingTop: "30px" }}>
                  <Grid container justifyContent="center">
                    <Grid item xs={5} container justifyContent="space-between" sx={{ marginTop: "20px" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={saving || !isChanged}
                        startIcon={saving && <CircularProgress size={14} />}
                        sx={{ marginBottom: "20px" }}
                      >
                        {saving ? "Lagre..." : "Lagre"}
                      </Button>
                      <Button variant="outlined" color="secondary" sx={{ marginBottom: "20px" }} onClick={cancelClicked}>
                        Avbryt
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Container>
        </>
      )}
    </>
  );
}
