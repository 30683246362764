import React, { useContext, useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { azureTokenContext } from "../App";

import { Routes, Route, useNavigate } from "react-router-dom";

import { Container, Grid, TextField, Button, Avatar, Box } from "@mui/material";

import "../style/topNav.css";

export default function TopNav() {
  const azureToken = useContext(azureTokenContext);
  const isAuthenticated = useIsAuthenticated();
  const [userName, setUserName] = useState("");
  const { instance } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    if (azureToken && azureToken.account) {
      setUserName(azureToken.account.name);
    }
  }, [azureToken, isAuthenticated]);

  const handleLogout = (loginType) => {
    if (loginType === "redirect") {
      instance.logoutRedirect().catch((e) => {
        console.log("error", e);
      });
    }
  };

  const Headline = (text) => {
    return (
      <div className="top-bar-header">
        <h3 style={{ marginLeft: "2rem" }}>{text.text}</h3>
        {/* insert a button for editing info when it is on the profile page */}
        {text.text === profileHeadline && (
          // <Grid item xs={12} md={8} sx={{ paddingTop: "30px" }}>
          //   <Grid container justifyContent="center" sx={{ marginTop: "20px" }}>
          <Button sx={{ marginLeft: "2rem" }} variant="contained" color="primary" onClick={editButtonClicked}>
            Rediger
          </Button>
          //   </Grid>
          // </Grid>
        )}
      </div>
    );
  };

  const editButtonClicked = () => {
    navigate("/edit-profile");
  };

  const profileHeadline = "Profil";

  return (
    <>
      <div className="topNavbar">
        {/* add headline to top navbar */}
        <Routes>
          <Route path="">
            <Route path="/tasks/*" element={<Headline text="Oppgaver" />} />
            <Route path="/authors-tasks" element={<Headline text="Oppgaver som forfatter" />} />
            <Route path="/contentreviewers-tasks" element={<Headline text="Oppgaver som fagredaktør" />} />
            <Route path="/proofreaders-tasks" element={<Headline text="Oppgaver som korrekturleser" />} />
            <Route path="/tasks" element={<Headline text="Oppgaver" />} />
            <Route path="/statistics" element={<Headline text="Statistikk" />} />
            <Route path="/profile" element={<Headline text={profileHeadline} />} />
            <Route path="/edit-profile" element={<Headline text="Endre profil" />} />
            <Route path="/support" element={<Headline text="Support" />} />
            <Route path="" element={<Headline text="" />} />
          </Route>
        </Routes>

        <div className="topNavBox">
          <p className="topNavText">Velkommen - {userName} | </p>
          <button className="signOutButton" onClick={() => handleLogout("redirect")}>
            Logg ut
          </button>
        </div>
      </div>
    </>
  );
}
