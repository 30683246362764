import React, { useEffect, useState } from "react";
import * as ApiCalls from "./components/Serving/ApiCalls";

// import { PageLayout } from "./components/PageLayout";
import SideBar from "./components/SideBar";
import MainPart from "./components/MainPart";
import MessageBox from "./components/Serving/MessageBox";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { BrowserRouter } from "react-router-dom";
import { loginRequest } from "./AppSetting";

//  Material UI license
import { LicenseInfo } from "@mui/x-license-pro";

import "./App.css";

export default function App() {
  const [azureToken, setAzureToken] = useState({});
  const [userId, setUserId] = useState("");
  const [userInfo, setUserInfo] = useState("");
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [userRoles, setUserRoles] = useState([]);
  const [messageBoxProp, setMessageBoxProp] = useState({});

  useEffect(() => {
    RequestAzureToken();

    // set Material UI license
    LicenseInfo.setLicenseKey(
      "68f6e27b7ea902e9a94a21e60c70e756Tz01NDkwNSxFPTE3MDA4MjM2Njk4MjMsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
    );

    setWebsiteIconAndTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //define roles
  useEffect(() => {
    if (azureToken?.account?.localAccountId) {
      ApiCalls.getUserInfo(azureToken)
        .then((res) => {
          setUserId(res.id);
          setUserInfo(res);
          // there is an useEffect for log login Info
        })
        .catch((err) => {
          setMessageBoxProp("errorGettingUserInfo");
          console.log("get userinfo error", err);
          log("ERROR", "Failed KPanel login", "KPanel", "", "");
          logMetric("KPanel", "", "Failed", "", "Failed login to KPanel", "", "");
        });
    }

    if (azureToken?.idTokenClaims?.roles) {
      defineRoles(azureToken.idTokenClaims.roles);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [azureToken]);

  useEffect(() => {
    if (userId) {
      log("INFO", "KPanel login", "KPanel", "", "");
      logMetric("KPanel", "", "Success", "", "Successful login to KPanel", "", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const defineRoles = (rolesFromToken) => {
    if (rolesFromToken.includes("KEmployee")) {
      setUserRoles(["KAuthor", "KContentReviewer", "KProofReader", "KEmployee"]);
    } else {
      setUserRoles(rolesFromToken);
    }
  };

  function setWebsiteIconAndTitle() {
    if (process.env.REACT_APP_ENVIRONMENT !== "production") {
      let logoLink = document.querySelector("link[rel~='icon']");
      let appleLogoLink = document.querySelector("link[rel~='apple-touch-icon']");

      document.title = process.env.REACT_APP_TITLE;

      if (logoLink) {
        logoLink.href = process.env.REACT_APP_LOGO;
      }
      if (appleLogoLink) {
        appleLogoLink.href = process.env.REACT_APP_LOGO;
      }
    }
  }

  function RequestAzureToken() {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        if (process.env.REACT_APP_ENVIRONMENT === "local") {
          console.log("Azure token in Local env", response);
        }
        setAzureTokenFunc(JSON.stringify(response));
      })
      .catch((e) => {
        console.log("error getting token", e);
        log("ERROR", "Failed token acquiring", "KPanel", "", "");
        logMetric("KPanel", "", "Failed", "", "Failed token acquiring in KPanel", "", "");
        // instance.acquireTokenPopup(request).then((response) => {
        //   setAzureTokenFunc(JSON.stringify(response));
        // });
      });
  }

  function setAzureTokenFunc(res) {
    let response = JSON.parse(res);
    setAzureToken(response);
  }

  const handleLogin = (loginType) => {
    if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log("error", e);
      });
    }
  };

  // Alert when a new version comes ----------
  var initialIndexFile = null;
  var currentIndexFile = null;

  const getInitialIndexFileFunc = () => {
    ApiCalls.getIndexFile().then((res) => {
      initialIndexFile = res;
    });
  };

  const compareIndexFiles = () => {
    ApiCalls.getIndexFile().then((res) => {
      currentIndexFile = res;
      if (initialIndexFile && currentIndexFile && currentIndexFile !== initialIndexFile) {
        setMessageBoxPropFunc("newVersion");
      }
    });
  };

  useEffect(() => {
    getInitialIndexFileFunc();

    setInterval(() => {
      compareIndexFiles();
      // 10 minutes
    }, 10 * 60 * 1000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setMessageBoxPropFunc = (prop) => {
    // since it is invocated by changes in prop, I want to make sure that it changes
    setMessageBoxProp("");
    setTimeout(() => {
      setMessageBoxProp(prop);
    }, 10);
  };

  // --------------------------------------------

  const log = (level, message, method, workItemId, userRole) => {
    ApiCalls.log(level, message, method, workItemId, userRole, userId, azureToken);
  };

  const logMetric = (category, subCategory, status, tag, message, workItemId, userRole) => {
    ApiCalls.logMetric(category, subCategory, status, tag, message, workItemId, userRole, userId, azureToken);
  };

  if (isAuthenticated) {
    return (
      <div className="pageRoot">
        <BrowserRouter>
          <azureTokenContext.Provider value={azureToken}>
            <userInfoContext.Provider value={[userInfo, setUserInfo]}>
              <userIdContext.Provider value={userId}>
                <userRolesContext.Provider value={userRoles}>
                  <messageBoxContext.Provider value={setMessageBoxProp}>
                    <MessageBox type={messageBoxProp} />
                    <SideBar />
                    <MainPart />
                  </messageBoxContext.Provider>
                </userRolesContext.Provider>
              </userIdContext.Provider>
            </userInfoContext.Provider>
          </azureTokenContext.Provider>
        </BrowserRouter>
      </div>
    );
  } else {
    handleLogin("redirect");
  }
}

export const azureTokenContext = React.createContext();
export const userIdContext = React.createContext();
export const userInfoContext = React.createContext();
export const userRolesContext = React.createContext();
export const messageBoxContext = React.createContext();
