import $ from "jquery";

var jsonTypeStr = "application/json; charset=utf-8";
var BffHost = process.env.REACT_APP_BACKEND_URL;

export const getUserInfo = async (azureToken) => {
  return new Promise(async function (resolve, reject) {
    const url = BffHost + "user/" + azureToken.account.localAccountId;

    $.ajax({
      type: "GET",
      url: url,
      dataType: "json",
      contentType: jsonTypeStr,
      accepts: jsonTypeStr,
      timeout: 30000, // 30 sec
      beforeSend: function (xhr) {
        //Include the bearer token in header
        if (azureToken.accessToken) {
          xhr.setRequestHeader("Authorization", "Bearer " + azureToken.accessToken);
        }
      },
    })
      .done((data) => {
        resolve(data);
      })
      .fail((err) => {
        reject(err);
        console.error("get userInfoByObjectId fail", err);
      });
  });
};

export const getTasks = async (userId, workItemId, azureToken) => {
  return new Promise(async function (resolve, reject) {
    let url = BffHost + "tasks/" + userId;
    if (workItemId) {
      url += "/" + workItemId;
    }
    $.ajax({
      type: "GET",
      url: url,
      dataType: "json",
      contentType: jsonTypeStr,
      accepts: jsonTypeStr,
      timeout: 30000, // 30 sec
      beforeSend: function (xhr) {
        //Include the bearer token in header
        if (azureToken.accessToken) {
          xhr.setRequestHeader("Authorization", "Bearer " + azureToken.accessToken);
        }
      },
    })
      .done((data) => {
        resolve(data);
      })
      .fail((err) => {
        reject(err);
        console.error("get tasks fail", err);
      });
  });
};

export const getTasksByRole = async (userId, role, azureToken) => {
  return new Promise(async function (resolve, reject) {
    let url = BffHost + "tasksbyrole/" + role + "/" + userId;
    $.ajax({
      type: "GET",
      url: url,
      dataType: "json",
      contentType: jsonTypeStr,
      accepts: jsonTypeStr,
      timeout: 30000, // 30 sec
      beforeSend: function (xhr) {
        //Include the bearer token in header
        if (azureToken.accessToken) {
          xhr.setRequestHeader("Authorization", "Bearer " + azureToken.accessToken);
        }
      },
    })
      .done((data) => {
        resolve(data);
      })
      .fail((err) => {
        reject(err);
        console.error("getTasksByRole  fail", err);
      });
  });
};

export const makeRevision = async (data, azureToken) => {
  return new Promise(async function (resolve, reject) {
    const url = BffHost + "revision";
    const json = JSON.stringify(data);
    $.ajax({
      type: "POST",
      url: url,
      data: json,
      dataType: "json",
      contentType: jsonTypeStr,
      accepts: jsonTypeStr,
      timeout: 30000, // 30 sec
      beforeSend: function (xhr) {
        //Include the bearer token in header
        if (azureToken.accessToken) {
          xhr.setRequestHeader("Authorization", "Bearer " + azureToken.accessToken);
        }
      },
    })
      .done(function (data) {
        resolve(data);
      })
      .fail(function (err) {
        reject(err);
        console.error("make revision fail", err);
      });
  });
};

export const contentReviewerStats = async (userId, azureToken) => {
  return new Promise(async function (resolve, reject) {
    let url =
      BffHost +
      "contentreviewer/" +
      userId +
      // Markus
      // "c75f5d40-73c8-4179-94a9-214591580bce" +
      "/stats";

    $.ajax({
      type: "GET",
      url: url,
      dataType: "json",
      contentType: jsonTypeStr,
      accepts: jsonTypeStr,
      timeout: 30000, // 30 sec
      beforeSend: function (xhr) {
        //Include the bearer token in header
        if (azureToken.accessToken) {
          xhr.setRequestHeader("Authorization", "Bearer " + azureToken.accessToken);
        }
      },
    })
      .done((data) => {
        resolve(data);
      })
      .fail((err) => {
        reject(err);
        console.error("get contentReviewer stats fail", err);
      });
  });
};

export const getIndexFile = async () => {
  return new Promise(async function (resolve, reject) {
    const url = window.location.origin;

    var myHeaders = new Headers();
    myHeaders.append("pragma", "no-cache");
    myHeaders.append("cache-control", "no-cache");

    var myInit = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(url, myInit)
      .then((data) => {
        let res = data.text();
        resolve(res);
      })
      .catch((err) => {
        reject(err);
        console.error("get index file fail", err);
      });
  });
};

export const log = (level, message, method, workItemId, userRole, userId, azureToken) => {
  return new Promise(function (resolve, reject) {
    const url = BffHost + "log";
    const data = {
      Level: level ?? "INFO",
      Message: message ?? "",
      Method: method ?? "",
      WorkItemId: workItemId ?? "",
      UserAgent: getUserAgent() ?? "",
      UserId: userId ?? "",
      UserRole: userRole ?? "",
    };
    const json = JSON.stringify(data);
    $.ajax({
      type: "POST",
      url: url,
      data: json,
      contentType: jsonTypeStr,
      // accepts: jsonTypeStr,
      timeout: 30000, // 30 sec
      beforeSend: function (xhr) {
        //Include the bearer token in header
        if (azureToken.accessToken) {
          xhr.setRequestHeader("Authorization", "Bearer " + azureToken.accessToken);
        }
      },
    })
      .done(function (data) {
        resolve(data);
      })
      .fail(function (err) {
        reject(console.error("send log message fail", err));
      });
  });
};

export const logMetric = (category, subCategory, status, tag, message, workItemId, userRole, userId, azureToken) => {
  return new Promise(function (resolve, reject) {
    const url = BffHost + "logmetric";
    const data = {
      Category: category ?? "",
      SubCategory: subCategory ?? "",
      WorkItemId: workItemId ?? "",
      Tag: tag ?? "",
      Status: status ?? "",
      Message: message ?? "",
      UserAgent: getUserAgent(),
      UserId: userId ?? "",
      UserRole: userRole ?? "",
    };
    const json = JSON.stringify(data);
    $.ajax({
      type: "POST",
      url: url,
      data: json,
      contentType: jsonTypeStr,
      // accepts: jsonTypeStr,
      timeout: 30000, // 30 sec
      beforeSend: function (xhr) {
        //Include the bearer token in header
        if (azureToken.accessToken) {
          xhr.setRequestHeader("Authorization", "Bearer " + azureToken.accessToken);
        }
      },
    })
      .done(function (data) {
        resolve(data);
      })
      .fail(function (err) {
        reject(console.error("send metric message fail", err));
      });
  });
};

export const GetUserSensitiveInfo = (userInfo, azureToken) => {
  return new Promise(function (resolve, reject) {
    const url = BffHost + "usersensitiveinfo/" + userInfo.id;
    $.ajax({
      type: "GET",
      url: url,
      contentType: "application/json",
      // accepts: jsonTypeStr,
      timeout: 30000, // 30 sec
      beforeSend: function (xhr) {
        //Include the bearer token in header
        if (azureToken.accessToken) {
          xhr.setRequestHeader("Authorization", "Bearer " + azureToken.accessToken);
        }
      },
    })
      .done(function (data) {
        resolve(data);
      })
      .fail(function (err) {
        reject(console.error("Get user sensitive info failed", err));
      });
  });
};

export const updateUserInfo = (newUserInfo, azureToken) => {
  return new Promise(function (resolve, reject) {
    const url = BffHost + "updateuserinfo/" + newUserInfo.id;
    const json = JSON.stringify(newUserInfo);
    $.ajax({
      type: "POST",
      url: url,
      data: json,
      contentType: "application/json",
      // accepts: jsonTypeStr,
      timeout: 30000, // 30 sec
      beforeSend: function (xhr) {
        //Include the bearer token in header
        if (azureToken.accessToken) {
          xhr.setRequestHeader("Authorization", "Bearer " + azureToken.accessToken);
        }
      },
    })
      .done(function (data) {
        resolve(data);
      })
      .fail(function (err) {
        reject(console.error("Update user info failed", err));
      });
  });
};

export const uploadUserProfile = (userInfo, profilePicture, azureToken) => {
  return new Promise(function (resolve, reject) {
    const url = BffHost + "uploadprofileimage/" + userInfo.id;
    const formData = new FormData();
    formData.append("profilePicture", profilePicture);
    $.ajax({
      type: "POST",
      url: url,
      data: formData,
      processData: false, // Prevent jQuery from automatically transforming the data into a query string
      contentType: false, // Let the browser set the content type
      // accepts: jsonTypeStr,
      timeout: 30000, // 30 sec
      beforeSend: function (xhr) {
        //Include the bearer token in header
        if (azureToken.accessToken) {
          xhr.setRequestHeader("Authorization", "Bearer " + azureToken.accessToken);
        }
      },
    })
      .done(function (data) {
        resolve(data);
      })
      .fail(function (err) {
        reject(console.error("Upload user profile picture failed", err));
      });
  });
};

export const getUnresolvedValidations = (userInfo, azureToken) => {
  return new Promise(function (resolve, reject) {
    const url = BffHost + "unresolvedvalidations/" + userInfo.id;
    $.ajax({
      type: "GET",
      url: url,
      timeout: 30000, // 30 sec
      beforeSend: function (xhr) {
        //Include the bearer token in header
        if (azureToken.accessToken) {
          xhr.setRequestHeader("Authorization", "Bearer " + azureToken.accessToken);
        }
      },
    })
      .done(function (data) {
        resolve(data);
      })
      .fail(function (err) {
        reject(console.error("Get unresolved validations failed", err));
      });
  });
};

const getUserAgent = () => {
  if (window.navigator && window.navigator.userAgent) {
    return window.navigator.userAgent;
  }
  return "unknown user agent";
};
