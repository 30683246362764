import React from "react";
import { Routes, Route } from "react-router-dom";
import TopNav from "./TopNav";
import Home from "./Home";
import Tasks from "./Tasks";
import AuthorTasks from "./AuthorTasks";
import ContentReviewerTasks from "./ContentReviewerTasks";
import ProofReaderTasks from "./ProofReaderTasks";
import Page404 from "./Serving/Page404";
import Support from "./Support";
import Profile from "./Profile";
import EditProfile from "./EditProfile";
import Statistics from "./Statistics";

import "../style/mainPart.css";

export default function MainPart() {
  return (
    <div className="mainPart">
      <TopNav />
      <div className="contentPart">
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="tasks/*" element={<Tasks />} />
            <Route path="tasks" element={<Tasks />} />
            <Route path="authors-tasks" element={<AuthorTasks />} />
            <Route path="contentreviewers-tasks" element={<ContentReviewerTasks />} />
            <Route path="proofreaders-tasks" element={<ProofReaderTasks />} />
            <Route path="statistics" element={<Statistics />} />
            <Route path="profile" element={<Profile />} />
            <Route path="edit-profile" element={<EditProfile />} />
            <Route path="support" element={<Support />} />
            <Route path="*" element={<Page404 />} />
          </Route>
        </Routes>
      </div>
    </div>
  );
}
